import { jsPDF } from "jspdf";


const fontref1 = require("../PDF/font/THSarabun-normal.js");
const fontref2 = require("../PDF/font/THSarabun Bold-normal.js");
const img = new Image();
img.src = require("../assets/logo.png");

const left = 25
const right = 10
const font_size = 14
const font_size_head = 16
const months_th = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
]
const months_th_mini = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
]

function thaiDate(d) {
    let date = new Date(d);
    let txt_date =
        date.getDate() +
        " " +
        months_th[date.getMonth()] +
        " " +
        (date.getFullYear() + 543);
    return txt_date;
}

// คำร้องขอ ปพ1
function createDoc1({ address = "", school = "", registra = "", director = "" }, { name = "", timestamp = "", father_name = "", mother_name = "", level = "", sub_level = "", std_id = "", semester = "", birthday = "", purpose = "", doc_name = "", type = "ปพ. 1" }) {
    const doc = new jsPDF("p", "mm", "a4");
    fontref1;
    fontref2;
    doc.getFontList();
    const pageWidth = doc.internal.pageSize.getWidth();
    const halfWidth = pageWidth / 2;
    doc.addImage(img, "png", pageWidth / 2 - 15, 5, 30, 30); //โลโก้เอกสาร

    doc.setFontSize(font_size_head); //ขนาดตัวอักษร
    doc.setFont("THSarabun Bold"); //ตัวหนา
    doc.text(doc_name, pageWidth / 2, 40, {
        align: "center",
    }); //ชื่อเอกสาร


    doc.setFont("THSarabun");
    doc.setFontSize(font_size);

    doc.text(address, pageWidth * 0.75, 48, {
        maxWidth: pageWidth - pageWidth * 0.75 - right,
    }); //ที่อยู่

    let date = new Date(timestamp);
    let txt_date =
        "วันที่ " +
        date.getDate() +
        " " +
        months_th[date.getMonth()] +
        " " +
        (date.getFullYear() + 543);
    doc.text(txt_date, pageWidth / 2 + 10, 68); //วันที่

    let doc_type = ""
    if (type == "ปพ.1") {
        doc_type = "ขอใบระเบียนแสดงผลการเรียน (รบ.1/ปพ.1)"
    } else {
        doc_type = "ขอใบระเบียนแสดงผลการเรียน (รบ.1/ปพ.1) 5 ภาคเรียน"
    }
    let headerText =
        "เรื่อง     " + doc_type +
        "\nเรียน     ผู้อำนวยการโรงเรียน" + school;

    let split = doc.splitTextToSize(
        headerText,
        pageWidth - left - right
    );
    doc.text(split, left, 78, { lineHeightFactor: 1.5 }); //ชื่อเรื่อง

    split = doc.splitTextToSize(
        "            ข้าพเจ้า_____________________________________________เลขบัตรประชาชน__________________________\nชั้น ม_______/________ปีการศึกษา____________________เกิดวันที่__________เดือน_________________พ.ศ._________\nบิดาชื่อ____________________________________________________________________________________________\nมารดาชื่อ__________________________________________________________________________________________\nมีความประสงค์จะขอใบระเบียนแสดงผลการเรียน  (รบ.1/ปพ.1) จำนวน 1 ฉบับ\nเพื่อ_____________________________________________________________________________________________________________________________________________________________________________________________________",
        pageWidth - left - right
    );
    doc.text(split, left, 98, { lineHeightFactor: 1.5 }); //เนื้อหา

    split = doc.splitTextToSize(
        "ทั้งนี้  ข้าพเจ้าได้แนบเอกสารหลักฐานต่างๆ มาด้วยแล้ว",
        pageWidth - left - right - 15
    );
    doc.text(split, left + 15, 153, { lineHeightFactor: 1.5 }); //เอกสาร

    split = doc.splitTextToSize(
        "1. รูปถ่ายขนาด 1.5 นิ้ว (ถ่ายไว้ไม่เกิน 6 เดือน)  จำนวน 2 รูป\n2. สำเนาบัตรประชาชน\n3. ใบแจ้งความเอกสารหาย (กรณีหายหรือชำรุด)",
        pageWidth - left - right - 20
    );
    doc.text(split, left + 20, 160, { lineHeightFactor: 1.5 }); //เอกสาร2

    doc.text("จึงเรียนมาเพื่อโปรดพิจารณา", left + 15, 190); //โปรดพิจารณา
    doc.text("ขอแสดงความนับถือ", left + 110, 198, { align: 'center' }); //ขอแสดงความนับถือ

    let txt_sign = "ลงชื่อ______________________________________\n( " + name + " ) "
    split = doc.splitTextToSize(
        txt_sign,
        pageWidth - left - right
    );
    doc.text(split, left + 110, 210, { lineHeightFactor: 1.5, align: 'center' }); //ลงชื่อ

    // doc.cell(left, 235, (pageWidth / 2) - left, 50, "ความเห็นนายทะเบียน");
    // doc.cell((pageWidth / 2), 235, (pageWidth / 2) - left, 50, "คำสั่งของผู้อำนวยการ");
    doc.rect(left, 235, (pageWidth / 2) - left, 50); //Fill and Border
    doc.rect((pageWidth / 2), 235, (pageWidth / 2) - left, 50); //Fill and Border
    doc.text("ความเห็นนายทะเบียน", left + 5, 242);
    doc.text("คำสั่งของผู้อำนวยการ", halfWidth + 5, 242);

    doc.circle(left + 20, 249, 2);
    doc.text("อนุญาต", left + 24, 250); //ขอนุญาตนายทะเบียน

    doc.circle(left + 40, 249, 2)
    doc.text("ไม่อนุญาต", left + 44, 250); //ไม่อนุญาต นายทะเบียน

    doc.circle(left + 102, 249, 2)
    doc.text("อนุญาต", left + 106, 250); //ขอนุญาต ผอ

    doc.circle(left + 122, 249, 2)
    doc.text("ไม่อนุญาต", left + 126, 250); //ไม่อนุญาต ผอ

    doc.line(left + 5, 255, halfWidth - 5, 255);//ความคิดเห็นนายทะเบียน
    doc.line(left + 5, 261, halfWidth - 5, 261);

    doc.line(halfWidth + 5, 255, pageWidth - left - 5, 255);//ความคิดเห็นผอ
    doc.line(halfWidth + 5, 261, pageWidth - left - 5, 261);

    let signY = 270
    doc.text("ลงชื่อ", left + 5, signY); //ลงชื่อนายทะเบียน
    doc.text("ลงชื่อ", left + 126 - 42, signY); //ลงชื่อผอ

    let length = doc.getTextWidth("ลงชื่อ");
    doc.line(left + 5 + length, signY, halfWidth - 5, signY);
    doc.line(halfWidth + 5 + length, signY, pageWidth - left - 5, signY);

    split = doc.splitTextToSize(
        "(" + registra + ")",
        halfWidth - left - 10
    );
    doc.text(split, halfWidth - 15 - left, signY + 6, { lineHeightFactor: 1, align: "center" }); //ชื่อนายทะเบียน

    split = doc.splitTextToSize(
        "(" + director + ")",
        halfWidth - left - 10
    );
    doc.text(split, halfWidth + 15 + left, signY + 6, { lineHeightFactor: 1, align: "center" }); //ชื่อ ผอ

    doc.setFontSize(10); //ขนาดตัวอักษร
    split = doc.splitTextToSize(
        "____/_____/____",
        halfWidth - left - 10
    );
    doc.text(split, halfWidth - 15 - left, signY + 11.5, { lineHeightFactor: 1, align: "center" }); //วันที่นายทะเบียน
    doc.text(split, halfWidth + 15 + left, signY + 11.5, { lineHeightFactor: 1, align: "center" }); //วันที่ ผอ

    doc.setFontSize(font_size); //ขนาดตัวอักษร
    length = doc.getTextWidth("            ข้าพเจ้า______________________")
    doc.text(name, left + length, 98, { align: 'center' })

    length = doc.getTextWidth("            ข้าพเจ้า_____________________________________________เลขบัตรประชาชน___________")
    doc.text(std_id, left + length, 98, { align: 'center' })

    length = doc.getTextWidth("ชั้น ม___")
    doc.text(level, left + length, 105, { align: 'center' })

    length = doc.getTextWidth("ชั้น ม_______/____")
    doc.text(sub_level, left + length, 105, { align: 'center' })

    length = doc.getTextWidth("ชั้น ม_______/________ปีการศึกษา__________")
    doc.text(semester.toString(), left + length, 105, { align: 'center' })

    // birthday = "";
    date = new Date(birthday);
    let d = date.getDate().toString();
    let m = date.getMonth();
    let y = date.getFullYear();

    if (d == "NaN") {
        d = ""
    }

    if (m.toString() == "NaN") {
        m = "";
    } else {
        m = months_th[m];
    }

    if (y.toString() == "NaN") {
        y = ""
    } else {
        y = y + 543
        y = y.toString()
    }


    length = doc.getTextWidth("ชั้น ม_______/________ปีการศึกษา____________________เกิดวันที่_____")
    doc.text(d, left + length, 105, { align: 'center' })

    length = doc.getTextWidth("ชั้น ม_______/________ปีการศึกษา____________________เกิดวันที่__________เดือน________")
    doc.text(m, left + length, 105, { align: 'center' })

    length = doc.getTextWidth("ชั้น ม_______/________ปีการศึกษา____________________เกิดวันที่__________เดือน_________________พ.ศ.____")
    doc.text(y, left + length, 105, { align: 'center' })

    length = doc.getTextWidth("บิดาชื่อ______")
    doc.text(father_name, left + length, 112.5)

    length = doc.getTextWidth("มารดาชื่อ____")
    doc.text(mother_name, left + length, 120)

    split = doc.splitTextToSize(
        "        " + purpose,
        pageWidth - left - right
    );
    doc.text(split, left, 135)

    return doc
}

function createDoc2({ address = "", school = "", head_eval = "", head_academic = "" }, { type = "", timestamp = "", name = "", teacher = "", level = "", sub_level = "", subject = "", subject_code = "", subject_type = "", term = "", year = "" }) {
    const doc = new jsPDF("p", "mm", "a4");
    fontref1;
    fontref2;
    doc.getFontList();

    const pageWidth = doc.internal.pageSize.getWidth();
    const halfWidth = pageWidth / 2;
    doc.addImage(img, "png", pageWidth / 2 - 15, 5, 30, 30); //โลโก้เอกสาร

    doc.setFontSize(font_size_head); //ขนาดตัวอักษร
    doc.setFont("THSarabun Bold"); //ตัวหนา
    let doc_name = ""
    let doc_name2 = ""
    if (type == "แก้ 0") {
        doc_name = "คำร้องขอ" + type
        doc_name2 = "ขอแก้ผลการเรียน 0"
    } else if (type == "แก้ ร") {
        doc_name = "คำร้องขอ" + type
        doc_name2 = "ขอแก้ผลการเรียน ร"
    } else {
        doc_name = "คำร้องขอ" + type
        doc_name2 = "ขอแก้ผลการเรียน มผ"
    }
    doc.text(doc_name, pageWidth / 2, 40, {
        align: "center",
    }); //ชื่อเอกสาร

    doc.setFont("THSarabun");
    doc.setFontSize(font_size);

    doc.text(address, pageWidth * 0.75, 48, {
        maxWidth: pageWidth - pageWidth * 0.75 - right,
    }); //ที่อยู่


    let date = new Date(timestamp);
    let txt_date =
        "วันที่ " +
        date.getDate() +
        " " +
        months_th[date.getMonth()] +
        " " +
        (date.getFullYear() + 543);
    doc.text(txt_date, pageWidth / 2 + 10, 65); //วันที่

    let headerText =
        "เรื่อง     " + doc_name2 +
        "\nเรียน    ผู้อำนวยการโรงเรียน" + school;

    let split = doc.splitTextToSize(
        headerText,
        pageWidth - left - right
    );
    doc.text(split, left, 78, { lineHeightFactor: 1.2 }); //ชื่อเรื่อง

    let contentY = 93
    let lineHeight = 7.5

    let length = doc.getTextWidth("เรื่อง     ");
    doc.text("ข้าพเจ้า", left + length, contentY);

    length = length + doc.getTextWidth("ข้าพเจ้า");
    doc.line(left + length + 1, contentY, pageWidth - 20 - 1, contentY);

    doc.text("ชั้น ม___/___", left, contentY + lineHeight)

    length = doc.getTextWidth("ชั้น ม___/___ ");
    doc.text("มีความประสงค์จะขอแก้ผลการเรียน “0” ในรายวิชา", left + length, contentY + lineHeight)

    length = doc.getTextWidth("ชั้น ม___/___ มีความประสงค์จะขอแก้ผลการเรียน “0” ในรายวิชา");
    doc.line(left + length + 1, contentY + lineHeight, pageWidth - 20 - 1, contentY + lineHeight);

    doc.text("รหัสวิชา", left, contentY + lineHeight * 2)

    length = doc.getTextWidth("รหัสวิชา");
    doc.line(left + length + 1, contentY + lineHeight * 2, left + length + 35, contentY + lineHeight * 2);

    doc.text("ซึ่งเป็นวิชา", left + length + 36, contentY + lineHeight * 2)

    length = length + doc.getTextWidth("ซึ่งเป็นวิชา") + 36
    doc.line(left + length + 1, contentY + lineHeight * 2, left + length + 35, contentY + lineHeight * 2);

    doc.text("ที่ติดเมื่อภาคเรียนที่", left + length + 36, contentY + lineHeight * 2)

    length = length + doc.getTextWidth("ที่ติดเมื่อภาคเรียนที่") + 36
    doc.line(left + length + 1, contentY + lineHeight * 2, pageWidth - 21, contentY + lineHeight * 2);

    doc.text("ปีการศึกษา", left, contentY + lineHeight * 3)

    length = doc.getTextWidth("ปีการศึกษา")
    doc.line(left + length + 1, contentY + lineHeight * 3, left + length + 35, contentY + lineHeight * 3);

    doc.text("ครูประจำวิชาชื่อ", left + length + 36, contentY + lineHeight * 3)

    length = length + 36 + doc.getTextWidth("ครูประจำวิชาชื่อ")
    doc.line(left + length + 1, contentY + lineHeight * 3, pageWidth - 21, contentY + lineHeight * 3);

    doc.text("จึงเรียนมาเพื่อโปรดพิจารณา", left + 15, contentY + lineHeight * 4.5); //โปรดพิจารณา

    contentY = 140;
    let txt_sign = "ลงชื่อ______________________________________\n( " + name + " ) "
    split = doc.splitTextToSize(
        txt_sign,
        pageWidth - left - right
    );
    doc.text(split, left + 110, contentY, { lineHeightFactor: 1.2, align: 'center' }); //ลงชื่อ

    contentY = 155;
    let boxHight = 50;
    doc.rect(left, contentY, pageWidth - left - 20, 8);
    doc.rect(left, contentY + 8, halfWidth - left, boxHight);
    doc.rect(halfWidth, contentY + 8, halfWidth - 20, boxHight);
    doc.rect(left, contentY + boxHight + 8, pageWidth - left - 20, 8);
    doc.rect(left, contentY + boxHight + 16, halfWidth - left, boxHight + 10);
    doc.rect(halfWidth, contentY + boxHight + 16, halfWidth - 20, boxHight + 10);

    doc.line(left + 5, contentY + 17, halfWidth - 5, contentY + 17);//ความคิดเห็นครูที่ปรึกษา
    doc.line(left + 5, contentY + lineHeight + 16, halfWidth - 5, contentY + lineHeight + 16);//ความคิดเห็นนายทะเบียน
    doc.line(left + 5, contentY + lineHeight * 2 + 15, halfWidth - 5, contentY + lineHeight * 2 + 15);//ความคิดเห็นนายทะเบียน

    doc.line(halfWidth + 5, contentY + 17, pageWidth - 25, contentY + 17);
    doc.line(halfWidth + 5, contentY + lineHeight + 16, pageWidth - 25, contentY + lineHeight + 16);
    doc.line(halfWidth + 5, contentY + lineHeight * 2 + 15, pageWidth - 25, contentY + lineHeight * 2 + 15);

    doc.text("ความเห็น/การพิจารณา", halfWidth, contentY + 5, { align: 'center' })
    doc.text("ผลการดำเนินงาน", halfWidth, contentY + boxHight + 13, { align: 'center' })

    lineHeight = 5
    doc.text("( " + teacher + " )", left + 40, contentY + lineHeight * 10, { align: 'center' })
    doc.text("ครูประจำวิชา", left + 40, contentY + lineHeight * 11, { align: 'center' })

    doc.text("( " + head_academic + " )", halfWidth + 40, contentY + lineHeight * 10, { align: 'center' })
    doc.text("หัวหน้างานวิชาการ", halfWidth + 40, contentY + lineHeight * 11, { align: 'center' })

    boxHight = 3;
    let boxWidth = 3;
    lineHeight = 7.5

    doc.rect(left + 5, contentY + lineHeight * 9.1, boxWidth, boxHight);
    doc.rect(left + 5, contentY + lineHeight * 10.1, boxWidth, boxHight);
    doc.rect(left + 5, contentY + lineHeight * 11.1, boxWidth, boxHight);
    doc.rect(left + 5, contentY + lineHeight * 12, boxWidth, boxHight);

    doc.rect(halfWidth + 5, contentY + lineHeight * 10.1, boxWidth, boxHight);
    doc.rect(halfWidth + 5, contentY + lineHeight * 11.1, boxWidth, boxHight);
    doc.rect(halfWidth + 5, contentY + lineHeight * 12, boxWidth, boxHight);

    doc.text("นักเรียนไม่มาติดต่อ/รับงาน/ดำเนินการใดๆ", left + boxWidth + 7, contentY + lineHeight * 9.1 + 2.5)
    doc.text("นักเรียนยังค้างส่งงานที่ได้รับมอบหมาย", left + boxWidth + 7, contentY + lineHeight * 10.1 + 2.5)
    doc.text("นักเรียนแก้ไขผลการเรียนเรียบร้อย", left + boxWidth + 7, contentY + lineHeight * 11.1 + 2.5)
    doc.text("______________________________", left + boxWidth + 7, contentY + lineHeight * 12 + 2.5)
    doc.text("การซ่อมผลการเรียนครั้งนี้   นักเรียนได้  _____ คะแนน", left + 5, contentY + lineHeight * 12.9 + 2.5)
    doc.text("ระดับผลการเรียน _____ (0 หรือ 1)", left + 5, contentY + lineHeight * 13.7 + 2.5)

    doc.text("ตรวจสอบแล้วถูกต้อง", halfWidth + boxWidth + 7, contentY + lineHeight * 10.1 + 2.5)
    doc.text("จัดเก็บข้อมูลผลการเรียนเรียบร้อย", halfWidth + boxWidth + 7, contentY + lineHeight * 11.1 + 2.5)
    doc.text("______________________________", halfWidth + boxWidth + 7, contentY + lineHeight * 12 + 2.5)

    lineHeight = 5
    doc.text("( " + teacher + " )", left + 40, contentY + lineHeight * 23.5, { align: 'center' })
    doc.text("ครูประจำวิชา", left + 40, contentY + lineHeight * 24.5, { align: 'center' })

    doc.text("( " + head_eval + " )", halfWidth + 40, contentY + lineHeight * 23.5, { align: 'center' })
    doc.text("หัวหน้างานวัดและประเมินผล", halfWidth + 40, contentY + lineHeight * 24.5, { align: 'center' })

    //ส่วนของการเติมข้อมูล
    contentY = 93
    lineHeight = 7.5

    length = doc.getTextWidth("เรื่อง     ");
    length = length + doc.getTextWidth("ข้าพเจ้า");
    doc.text(name, left + length + 5, contentY - 0.7);

    length = doc.getTextWidth("ชั้น ม ")
    doc.text(level, left + length + 1, contentY + lineHeight);

    length = doc.getTextWidth("ชั้น ม___/")
    doc.text(sub_level, left + length + 1, contentY + lineHeight);

    length = doc.getTextWidth("ชั้น ม___/___ มีความประสงค์จะขอแก้ผลการเรียน “0” ในรายวิชา   ")
    doc.text(subject, left + length + 1, contentY + lineHeight - 0.7);

    length = doc.getTextWidth("รหัสวิชา")
    doc.text(subject_code, left + length + 18, contentY + lineHeight * 2 - 0.7, { align: 'center' });

    length = doc.getTextWidth("รหัสวิชา ซึ่งเป็นวิชา")
    doc.text(subject_type, left + length + 53, contentY + lineHeight * 2 - 0.7, { align: 'center' });

    doc.text(term, pageWidth - 43, contentY + lineHeight * 2 - 0.7, { align: 'center' });

    doc.text(year.toString(), left + 33, contentY + lineHeight * 3 - 0.7, { align: 'center' });

    doc.text(teacher, left + 75, contentY + lineHeight * 3 - 0.7);

    return doc
}

export { createDoc1, createDoc2, months_th_mini,thaiDate }